<template>
  <v-dialog
    v-model="user_dialog"
    width="280"
    content-class="card card-sm overflow-y-auto deelnemers-dialog"
  >
    <div class="d-flex">
      <h4>
        {{
          $i18n.locale == "nl"
            ? "Deelnemers"
            : "These colleagues also participate"
        }}
      </h4>

      <v-btn
        icon
        dark
        variant="text"
        class="ml-auto close-icon-deelnemers"
        @click="$emit('close-dialog')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>

    <v-list>
      <v-list-item v-for="(item, index) in enrolls" :key="index">
        <div class="d-flex">
          <v-avatar
            size="40"
            :image="
              item.profilepicture
                ? `${configdata.url.cdn}/users/avatars/${item.profilepicture}`
                : `${configdata.url.cdn}/default_images/default_avatar_image.png`
            "
          ></v-avatar>
          <k-spacing x="4"></k-spacing>
          <span class="userdialog-text my-auto">
            {{ truncateContent(item.firstname + " " + item.lastname) }}
          </span>
        </div>
      </v-list-item>
    </v-list>
  </v-dialog>
</template>

<script>
import config from "../../config";

export default {
  components: {},

  props: {
    enrolls: Array,
  },
  computed: {
    configdata() {
      return config;
    },
  },
  data: () => ({
    user_dialog: false,
  }),

  methods: {
    truncateContent(content) {
      const words = content.split(" ");
      if (words.length > 10) {
        const truncated = words.slice(0, 10).join(" ") + "...";
        return truncated;
      } else {
        return content;
      }
    },
    deleteitem() {
      this.$emit("delete");
    },
  },
};
</script>
