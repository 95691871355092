<template>
  <v-dialog
    v-model="time_dialog"
    width="768"
    content-class="card pa-3   pa-md-7 register-dialog"
  >
    <v-btn
      icon
      dark
      variant="text"
      class="dialog-close-btn"
      @click="$emit('close-dialog')"
    >
      <v-icon>mdi-close</v-icon>
    </v-btn>

    <v-form
      v-if="step === 1"
      class="overflow-y-scroll pt-3 pb-3"
      ref="step1Form"
    >
      <div v-if="step === 1" class="stepper-view px-3">
        <span class="step-nr">{{
          $t("components.changeActivityDialogDialog.dialog_title_group_1")
        }}</span>
        <k-spacing y="8"></k-spacing>
        <p class="step-subtitle">
          {{
            $i18n.locale == "nl" ? "Wijzig aanmelding" : "Change registration"
          }}
          {{ $i18n.locale == "nl" ? "groepsactiviteit" : "group activity" }}
        </p>
        <h3 class="step-title mb-4 mt-1" v-if="$i18n.locale == 'nl'">
          {{
            $i18n.locale == "nl"
              ? activity.content.nl.banner.title
              : activity.content.eng.banner.title
          }}
        </h3>
        <span class="enroll-date">
          {{
            new Date(enroll.date * 1000)
              .toLocaleDateString("nl-NL", {
                day: "numeric",
                month: "short",
                year: "numeric",
              })
              .replace(/(\w{3})/, "$1.")
          }}
          {{ enroll.start }} -
          {{ enroll.end }}
        </span>
        <k-spacing y="6"></k-spacing>
        <label class="input-label" for="choose-settling">{{
          $t("components.changeActivityDialogDialog.dialog_stepper_branche_l")
        }}</label>
        <v-select
          class="mt-1"
          v-model="registeractivity.location"
          :items="filteredLocations(activity.locations)"
          item-value="id"
          item-title="data.city"
          density="compact"
          variant="solo"
          :placeholder="
            $t(
              'components.changeActivityDialogDialog.dialog_stepper_branche_ph'
            )
          "
          name="choose-settling"
          validate-on-blur
        />

        <k-spacing v-if="!is_mobile" y="8"></k-spacing>
        <k-spacing v-else y="4"></k-spacing>

        <v-row>
          <v-col cols="12" :class="!is_mobile ? 'd-flex' : ''">
            <v-btn
              :block="is_mobile"
              :disabled="!registeractivity.location"
              class="btn text-white quickfix-btn"
              color="var(--company-primary)"
              @click="step = 2"
            >
              {{ $i18n.locale == "nl" ? "Ga verder" : "Continue" }}
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </v-form>
    <v-form
      v-if="step === 2"
      class="overflow-y-scroll pt-3 pb-3"
      ref="step2Form"
    >
      <div v-if="step === 2" class="stepper-view px-3">
        <span class="step-nr">{{
          $t("components.changeActivityDialogDialog.dialog_title_group_2")
        }}</span>
        <k-spacing y="8"></k-spacing>
        <p class="step-subtitle">
          {{
            $i18n.locale == "nl" ? "Wijzig aanmelding" : "Change registration"
          }}
        </p>
        <h3 class="step-title mb-4 mt-1" v-if="$i18n.locale == 'nl'">
          {{
            $i18n.locale == "nl"
              ? activity.content.nl.banner.title
              : activity.content.eng.banner.title
          }}
        </h3>
        <span class="enroll-date">
          {{
            new Date(enroll.date * 1000)
              .toLocaleDateString("nl-NL", {
                day: "numeric",
                month: "short",
                year: "numeric",
              })
              .replace(/(\w{3})/, "$1.")
          }}
          {{ enroll.start }} -
          {{ enroll.end }}
        </span>

        <k-spacing y="6"></k-spacing>
        <label v-if="!is_mobile" class="input-label mb-4" for="choose-date">{{
          $t("components.registerActivityDialog.dialog_date_choose")
        }}</label>
        <div v-if="!is_mobile">
          <div
            v-for="(item, index) in groupDatesFiltered"
            :key="index"
            class="group-date-card"
            :class="getClassForDate(item)"
          >
            <div
              @click="
                !item.disabled &&
                !functions.checkDate(item.date, item.specialist.slot.start)
                  ? selectItem(item)
                  : ''
              "
            >
              <v-row class="my-auto" v-if="item.specialist.slot">
                <v-col cols="3" md="2" class="my-auto border-col">
                  <span class="group-register-list-month d-block">
                    {{
                      new Date(item.date)
                        .toLocaleDateString("nl-NL", {
                          month: "short",
                        })
                        .replace(/(\w{3})/, "$1.")
                    }}
                  </span>
                  <span class="group-register-list-date d-block">
                    {{
                      new Date(item.date).toLocaleDateString("nl-NL", {
                        day: "numeric",
                      })
                    }}
                  </span>
                  <span class="group-register-list-day d-block">
                    {{
                      new Date(item.date).toLocaleDateString("nl-NL", {
                        weekday: "long",
                      })
                    }}
                  </span>
                </v-col>
                <v-col cols="9" md="5">
                  <span class="group-register-list-starttime">
                    {{ item.specialist.slot.start }} -
                    {{ item.specialist.slot.end }}
                  </span>
                  <k-spacing y="4"></k-spacing>

                  <span
                    v-if="activity.location_type == 'location'"
                    class="group-register-list-address d-block"
                  >
                    {{ item.specialist.slot.address.line1 }}
                  </span>
                  <span v-else class="group-register-list-address d-block">
                    {{ item.specialist.slot.address.meeting_link }}
                  </span>
                  <span
                    class="group-register-list-address"
                    v-if="activity.location_type == 'location'"
                  >
                    {{ item.specialist.slot.address.room }} |
                  </span>
                  <span class="group-register-list-address">
                    {{ item.specialist.name }}
                  </span>
                </v-col>
                <v-col cols="12" md="5" class="d-flex flex-column">
                  <span class="available-spots-text"
                    >Nog maar
                    {{
                      item.specialist.slot.spots -
                      item.specialist.slot.enrolls.length
                    }}
                    plekken beschikbaar</span
                  >
                  <k-spacing y="4"></k-spacing>
                  <UserenrollsDialog
                    v-model="user_dialog"
                    @close-dialog="user_dialog = false"
                    v-if="slotId == item.specialist.slot.id"
                    :enrolls="item.specialist.slot.enrolls"
                  />
                  <v-tooltip
                    v-if="
                      functions.checkDate(item.date, item.specialist.slot.start)
                    "
                  >
                    <template v-slot:activator="{ props }">
                      <div v-bind="props" class="ml-auto mr-7 mt-n2">
                        <v-btn variant="text" icon>
                          <v-icon size="32">
                            <svg
                              width="32"
                              height="32"
                              viewBox="0 0 32 32"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g id="info">
                                <mask
                                  id="mask0_4328_23687"
                                  style="mask-type: alpha"
                                  maskUnits="userSpaceOnUse"
                                  x="0"
                                  y="0"
                                  width="32"
                                  height="32"
                                >
                                  <rect
                                    id="Bounding box"
                                    width="32"
                                    height="32"
                                    fill="#D9D9D9"
                                  />
                                </mask>
                                <g mask="url(#mask0_4328_23687)">
                                  <path
                                    id="info_2"
                                    d="M14.667 22.6667H17.3337V14.6667H14.667V22.6667ZM16.0003 12.0001C16.3781 12.0001 16.6948 11.8723 16.9503 11.6167C17.2059 11.3612 17.3337 11.0445 17.3337 10.6667C17.3337 10.289 17.2059 9.9723 16.9503 9.71675C16.6948 9.46119 16.3781 9.33341 16.0003 9.33341C15.6225 9.33341 15.3059 9.46119 15.0503 9.71675C14.7948 9.9723 14.667 10.289 14.667 10.6667C14.667 11.0445 14.7948 11.3612 15.0503 11.6167C15.3059 11.8723 15.6225 12.0001 16.0003 12.0001ZM16.0003 29.3334C14.1559 29.3334 12.4225 28.9834 10.8003 28.2834C9.1781 27.5834 7.76699 26.6334 6.56699 25.4334C5.36699 24.2334 4.41699 22.8223 3.71699 21.2001C3.01699 19.5779 2.66699 17.8445 2.66699 16.0001C2.66699 14.1556 3.01699 12.4223 3.71699 10.8001C4.41699 9.17786 5.36699 7.76675 6.56699 6.56675C7.76699 5.36675 9.1781 4.41675 10.8003 3.71675C12.4225 3.01675 14.1559 2.66675 16.0003 2.66675C17.8448 2.66675 19.5781 3.01675 21.2003 3.71675C22.8225 4.41675 24.2337 5.36675 25.4337 6.56675C26.6337 7.76675 27.5837 9.17786 28.2837 10.8001C28.9837 12.4223 29.3337 14.1556 29.3337 16.0001C29.3337 17.8445 28.9837 19.5779 28.2837 21.2001C27.5837 22.8223 26.6337 24.2334 25.4337 25.4334C24.2337 26.6334 22.8225 27.5834 21.2003 28.2834C19.5781 28.9834 17.8448 29.3334 16.0003 29.3334Z"
                                    fill="#4E4E4E"
                                  />
                                </g>
                              </g>
                            </svg>
                          </v-icon>
                        </v-btn>
                      </div>
                    </template>
                    <div class="tooltip-area">
                      <span class="text-normal text-white tooltip-text">
                        {{
                          $i18n.locale == "nl"
                            ? `Je bent al aangemeld voor ${functions.getEnroll(
                                item.date,
                                item.specialist.slot.start,
                                $i18n.locale
                              )} op dit tijdstip`
                            : `You are already signed up for ${functions.getEnroll(
                                item.date,
                                item.specialist.slot.start,
                                $i18n.locale
                              )} at this time`
                        }}
                      </span>
                    </div>
                  </v-tooltip>
                  <div class="d-flex">
                    <v-avatar
                      :class="`enroll-avatars-${enroll_index}`"
                      :style="`z-index: ${100 - enroll_index}`"
                      v-for="(enroll, enroll_index) in filteredSpecialists(
                        item.specialist.slot.enrolls
                      )"
                      :key="enroll_index"
                      color="var(--company-primary)"
                      size="40"
                      :image="
                        enroll.profilepicture
                          ? `${configdata.url.cdn}/users/avatars/${enroll.profilepicture}`
                          : `${configdata.url.cdn}/default_images/default_avatar_image.png`
                      "
                    >
                    </v-avatar>
                    <span
                      @click="selectEnroll(item.specialist.slot.id)"
                      class="more-spots-text my-auto"
                      v-if="item.specialist.slot.enrolls.length > 5"
                      >{{
                        `+${item.specialist.slot.enrolls.length - 5} ${
                          $i18n.locale == "en" ? "More" : "Meer"
                        }`
                      }}
                    </span>
                  </div>
                </v-col>
              </v-row>
            </div>
          </div>
        </div>
        <v-slide-group v-else show-arrows="false" direction="horizontal">
          <v-slide-group-item
            v-for="(item, index) in groupDatesFiltered"
            :key="index"
          >
            <div
              @click="
                !item.disabled &&
                !functions.checkDate(item.date, item.specialist.slot.start)
                  ? selectItem(item)
                  : ''
              "
              class="group-date-card"
              :class="getClassForDate(item)"
            >
              <v-row class="" v-if="item.specialist.slot">
                <v-col
                  cols="3"
                  md="2"
                  class="d-flex flex-column align-center justify-center border-col"
                >
                  <span class="group-register-list-month d-block">
                    {{
                      new Date(item.date)
                        .toLocaleDateString("nl-NL", {
                          month: "short",
                        })
                        .replace(/(\w{3})/, "$1.")
                    }}
                  </span>
                  <span class="group-register-list-date d-block">
                    {{
                      new Date(item.date).toLocaleDateString("nl-NL", {
                        day: "numeric",
                      })
                    }}
                  </span>
                  <span class="group-register-list-day d-block">
                    {{
                      new Date(item.date).toLocaleDateString("nl-NL", {
                        weekday: "long",
                      })
                    }}
                  </span>
                </v-col>

                <v-col cols="9" md="5">
                  <div class="d-flex">
                    <span class="group-register-list-starttime">
                      {{ item.specialist.slot.start }} -
                      {{ item.specialist.slot.end }}
                    </span>
                    <v-tooltip
                      v-if="
                        functions.checkDate(
                          item.date,
                          item.specialist.slot.start
                        )
                      "
                    >
                      <template v-slot:activator="{ props }">
                        <div v-bind="props" class="ml-auto">
                          <v-btn variant="text" icon class="pa-0 mt-n4 mr-n6">
                            <v-icon size="24">
                              <svg
                                width="32"
                                height="32"
                                viewBox="0 0 32 32"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g id="info">
                                  <mask
                                    id="mask0_4328_23687"
                                    style="mask-type: alpha"
                                    maskUnits="userSpaceOnUse"
                                    x="0"
                                    y="0"
                                    width="32"
                                    height="32"
                                  >
                                    <rect
                                      id="Bounding box"
                                      width="32"
                                      height="32"
                                      fill="#D9D9D9"
                                    />
                                  </mask>
                                  <g mask="url(#mask0_4328_23687)">
                                    <path
                                      id="info_2"
                                      d="M14.667 22.6667H17.3337V14.6667H14.667V22.6667ZM16.0003 12.0001C16.3781 12.0001 16.6948 11.8723 16.9503 11.6167C17.2059 11.3612 17.3337 11.0445 17.3337 10.6667C17.3337 10.289 17.2059 9.9723 16.9503 9.71675C16.6948 9.46119 16.3781 9.33341 16.0003 9.33341C15.6225 9.33341 15.3059 9.46119 15.0503 9.71675C14.7948 9.9723 14.667 10.289 14.667 10.6667C14.667 11.0445 14.7948 11.3612 15.0503 11.6167C15.3059 11.8723 15.6225 12.0001 16.0003 12.0001ZM16.0003 29.3334C14.1559 29.3334 12.4225 28.9834 10.8003 28.2834C9.1781 27.5834 7.76699 26.6334 6.56699 25.4334C5.36699 24.2334 4.41699 22.8223 3.71699 21.2001C3.01699 19.5779 2.66699 17.8445 2.66699 16.0001C2.66699 14.1556 3.01699 12.4223 3.71699 10.8001C4.41699 9.17786 5.36699 7.76675 6.56699 6.56675C7.76699 5.36675 9.1781 4.41675 10.8003 3.71675C12.4225 3.01675 14.1559 2.66675 16.0003 2.66675C17.8448 2.66675 19.5781 3.01675 21.2003 3.71675C22.8225 4.41675 24.2337 5.36675 25.4337 6.56675C26.6337 7.76675 27.5837 9.17786 28.2837 10.8001C28.9837 12.4223 29.3337 14.1556 29.3337 16.0001C29.3337 17.8445 28.9837 19.5779 28.2837 21.2001C27.5837 22.8223 26.6337 24.2334 25.4337 25.4334C24.2337 26.6334 22.8225 27.5834 21.2003 28.2834C19.5781 28.9834 17.8448 29.3334 16.0003 29.3334Z"
                                      fill="#4E4E4E"
                                    />
                                  </g>
                                </g>
                              </svg>
                            </v-icon>
                          </v-btn>
                        </div>
                      </template>
                      <div class="tooltip-area">
                        <span class="text-normal text-white tooltip-text">
                          {{
                            $i18n.locale == "nl"
                              ? `Je bent al aangemeld voor ${functions.getEnroll(
                                  item.date,
                                  item.specialist.slot.start,
                                  $i18n.locale
                                )} op dit tijdstip`
                              : `You are already signed up for ${functions.getEnroll(
                                  item.date,
                                  item.specialist.slot.start,
                                  $i18n.locale
                                )} at this time`
                          }}
                        </span>
                      </div>
                    </v-tooltip>
                  </div>
                  <k-spacing y="4"></k-spacing>

                  <span
                    v-if="item.specialist.slot.location_type == 'location'"
                    class="group-register-list-address d-block mb-2"
                  >
                    {{ item.specialist.slot.address.line1 }}
                  </span>
                  <span v-else class="group-register-list-address d-block mb-2">
                    {{ item.specialist.slot.address.meeting_link }}
                  </span>
                  <span
                    class="group-register-list-address"
                    v-if="item.specialist.slot.location_type == 'location'"
                  >
                    {{ item.specialist.slot.address.room }} |
                  </span>
                  <span class="group-register-list-address specialist">
                    {{ item.specialist.name }}
                  </span>
                </v-col>
                <v-col
                  cols="12"
                  md="5"
                  class="d-flex flex-column ga-2"
                  v-if="!is_mobile"
                >
                  <span class="available-spots-text"
                    >Nog maar
                    {{
                      item.specialist.slot.spots -
                      item.specialist.slot.enrolls.length
                    }}
                    plekken beschikbaar</span
                  >
                  <UserenrollsDialog
                    v-model="user_dialog"
                    @close-dialog="user_dialog = false"
                    v-if="slotId == item.specialist.slot.id"
                    :enrolls="item.specialist.slot.enrolls"
                  />
                  <div class="d-flex">
                    <v-avatar
                      :class="`enroll-avatars-${enroll_index}`"
                      :style="`z-index: ${100 - enroll_index}`"
                      v-for="(enroll, enroll_index) in filteredSpecialists(
                        item.specialist.slot.enrolls
                      )"
                      :key="enroll_index"
                      size="40"
                      :image="
                        enroll.profilepicture
                          ? `${configdata.url.cdn}/users/avatars/${enroll.profilepicture}`
                          : `${configdata.url.cdn}/default_images/default_avatar_image.png`
                      "
                    >
                    </v-avatar>
                    <span
                      @click="selectEnroll(item.specialist.slot.id)"
                      class="more-spots-text my-auto"
                      v-if="item.specialist.slot.enrolls.length > 5"
                      >{{
                        `+${item.specialist.slot.enrolls.length - 5}
                    ${$i18n.locale == "en" ? "More" : "Meer"}`
                      }}
                    </span>
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-slide-group-item>
        </v-slide-group>
        <k-spacing y="8"></k-spacing>
        <v-row>
          <v-col cols="12" :class="!is_mobile ? 'd-flex' : ''">
            <v-btn
              v-if="is_mobile"
              :block="is_mobile"
              :disabled="!registeractivity.date.date"
              class="btn text-white quickfix-btn"
              color="var(--company-primary)"
              @click="step = 3"
            >
              {{ $i18n.locale == "nl" ? "Ga verder" : "Continue" }}
            </v-btn>
            <k-spacing v-if="is_mobile" y="4"></k-spacing>

            <v-btn
              :block="is_mobile"
              class="btn btn-company-primary quickfix-btn"
              color="var(--company-primary)"
              variant="outlined"
              @click="step -= 1"
            >
              {{ $t("components.changeActivityDialogDialog.dialog_prev_btn") }}
            </v-btn>
            <k-spacing x="4"></k-spacing>
            <v-btn
              v-if="!is_mobile"
              :disabled="!registeractivity.date.date"
              class="btn text-white quickfix-btn"
              color="var(--company-primary)"
              @click="step = 3"
            >
              {{ $i18n.locale == "nl" ? "Ga verder" : "Continue" }}
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </v-form>

    <div
      v-if="step === 3"
      class="stepper-view px-3 overflow-y-scroll pt-3 pb-3"
      ref="step3Form"
    >
      <span>{{
        $t("components.changeActivityDialogDialog.dialog_title_group_3")
      }}</span>
      <k-spacing y="8"></k-spacing>
      <p class="step-subtitle">
        {{ $i18n.locale == "nl" ? "Wijzig aanmelding" : "Change registration" }}
        {{ $i18n.locale == "nl" ? "groepsactiviteit" : "group activity" }}
      </p>
      <h3 class="step-title mb-4 mt-1" v-if="$i18n.locale == 'nl'">
        {{
          $i18n.locale == "nl"
            ? activity.content.nl.banner.title
            : activity.content.eng.banner.title
        }}
      </h3>
      <span class="enroll-date">
        {{
          new Date(enroll.date * 1000)
            .toLocaleDateString("nl-NL", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })
            .replace(/(\w{3})/, "$1.")
        }}
        {{ enroll.start }} -
        {{ enroll.end }}
      </span>

      <k-spacing y="8"></k-spacing>
      <label class="input-label" for="choose-specialist">{{
        $i18n.locale == "nl" ? "Aanmeld gegevens" : "Registration details"
      }}</label>
      <k-spacing y="4"></k-spacing>
      <div v-if="registeractivity.date.date">
        <v-row class="lh-fix card-light summary-cards pt-2">
          <v-col
            v-if="registeractivity.date.slot.location_type != 'online'"
            cols="12"
            md="3"
          >
            <span class="register-card-summary-text">
              {{
                $t(
                  "components.registerActivityDialog.dialog_stepper_title_4_l_summary_1"
                )
              }}
            </span>
          </v-col>
          <v-col
            v-if="registeractivity.date.slot.location_type != 'online'"
            cols="12"
            md="9"
          >
            <span class="register-card-summary-answer">
              {{ activity.locations[locationIndex].data.city }}
            </span>
          </v-col>
          <v-col
            v-if="registeractivity.date.slot.location_type == 'online'"
            cols="12"
            md="3"
            class="mt-1"
          >
            <span class="register-card-summary-text"> Link </span>
          </v-col>
          <v-col
            v-if="registeractivity.date.slot.location_type == 'online'"
            cols="12"
            md="9"
          >
            <span class="register-card-summary-answer">
              <a
                :href="registeractivity.date.slot.address.meeting_link"
                target="_blank"
                >{{ registeractivity.date.slot.address.meeting_link }}</a
              >
            </span>
          </v-col>
          <v-col
            v-if="registeractivity.date.slot.location_type == 'online'"
            cols="12"
            md="3"
            class="mt-1"
          >
            <span class="register-card-summary-text"> Meeting ID </span>
          </v-col>
          <v-col
            v-if="registeractivity.date.slot.location_type == 'online'"
            cols="12"
            md="9"
          >
            <span class="register-card-summary-answer">
              {{ registeractivity.date.slot.address.meeting_id }}
            </span>
          </v-col>
          <v-col
            v-if="registeractivity.date.slot.location_type == 'online'"
            cols="12"
            md="3"
            class="mt-1"
          >
            <span class="register-card-summary-text"> Passcode </span>
          </v-col>
          <v-col
            v-if="registeractivity.date.slot.location_type == 'online'"
            cols="12"
            md="9"
          >
            <span class="register-card-summary-answer">
              {{ registeractivity.date.slot.address.passcode }}
            </span>
          </v-col>
          <v-col
            cols="12"
            md="3"
            class="mt-1"
            v-if="
              registeractivity.date.slot.location_type != 'online' &&
              registeractivity.date.slot.address.room
            "
          >
            <span class="register-card-summary-text">
              {{
                $t(
                  "components.registerActivityDialog.dialog_stepper_title_4_l_summary_2"
                )
              }}
            </span>
          </v-col>
          <v-col
            v-if="
              registeractivity.date.slot.location_type != 'online' &&
              registeractivity.date.slot.address.room
            "
            cols="12"
            md="9"
          >
            <span class="register-card-summary-answer">
              {{ registeractivity.date.slot.address.room }}
            </span>
          </v-col>
          <v-col cols="12" md="3" class="mt-1">
            <span class="register-card-summary-text">
              {{
                $t(
                  "components.registerActivityDialog.dialog_stepper_title_4_l_summary_3"
                )
              }}
            </span>
          </v-col>
          <v-col cols="12" md="9">
            <span class="register-card-summary-answer">
              {{ registeractivity.date.specialist }}
            </span>
          </v-col>

          <v-col cols="12" md="3" class="mt-1">
            <span class="register-card-summary-text">
              {{
                $t(
                  "components.registerActivityDialog.dialog_stepper_title_4_l_summary_4"
                )
              }}
            </span>
          </v-col>
          <v-col cols="12" md="9">
            <span class="register-card-summary-answer">
              {{
                new Date(registeractivity.date.date)
                  .toLocaleDateString("nl-NL", {
                    day: "numeric",
                    month: "short",
                    year: "numeric",
                  })
                  .replace(/(\w{3})/, "$1.")
              }}
            </span>
          </v-col>

          <v-col cols="12" md="3" class="mt-1">
            <span class="register-card-summary-text">
              {{
                $t(
                  "components.registerActivityDialog.dialog_stepper_title_4_l_summary_5"
                )
              }}
            </span>
          </v-col>
          <v-col cols="12" md="9">
            <span class="register-card-summary-answer">
              {{ registeractivity.date.slot.start }} -
              {{ registeractivity.date.slot.end }}
            </span>
          </v-col>
        </v-row>
        <k-spacing y="4"></k-spacing>
      </div>

      <v-checkbox
        class="ml-n2"
        :label="
          $t('components.changeActivityDialogDialog.dialog_stepper_checkbox_l')
        "
        color="var(--company-primary)"
        v-model="registeractivity.reminder"
      >
      </v-checkbox>
      <k-spacing y="4"></k-spacing>

      <v-row>
        <v-col cols="12" :class="!is_mobile ? 'd-flex' : ''">
          <v-btn
            :block="is_mobile"
            class="btn btn-company-primary quickfix-btn"
            color="var(--company-primary)"
            variant="outlined"
            @click="step -= 1"
          >
            {{ $t("components.changeActivityDialogDialog.dialog_prev_btn") }}
          </v-btn>
          <k-spacing v-if="is_mobile" y="4"></k-spacing>

          <k-spacing x="4"></k-spacing>

          <v-btn
            :block="is_mobile"
            class="btn text-white quickfix-btn"
            @click="register(), checkOverflow()"
            color="var(--company-primary)"
          >
            {{ $i18n.locale == "nl" ? "Ik meld mij aan" : "Submit" }}
          </v-btn>
        </v-col>
      </v-row>
    </div>

    <div class="stepper-view px-3 pb-3" v-if="step === 4" ref="step4Form">
      <div class="banner-activity-thnx-img">
        <img :src="`${configdata.url.cdn}/activities/${activity.image}`" />
      </div>
      <k-spacing y="8"></k-spacing>
      <h3 class="step-title">
        {{
          $i18n.locale == "nl"
            ? "Wijziging is gelukt!"
            : "Change is successful!"
        }}
      </h3>
      <k-spacing y="4"></k-spacing>

      <p
        v-html="
          $i18n.locale == 'nl'
            ? `Jouw aanmelding is succesvol verplaatst naar  <strong>${new Date(
                registeractivity.date.date
              )
                .toLocaleDateString('nl-NL', {
                  day: 'numeric',
                  month: 'short',
                  year: 'numeric',
                })
                .replace(/(\w{3})/, '$1.')}</strong>, <strong>${
                registeractivity.date.slot.start
              } - ${registeractivity.date.slot.end}</strong>.`
            : `Your enrollment has been successfully moved to <strong>${new Date(
                registeractivity.date.date
              )
                .toLocaleDateString('nl-NL', {
                  day: 'numeric',
                  month: 'short',
                  year: 'numeric',
                })
                .replace(/(\w{3})/, '$1.')}</strong>, <strong>${
                registeractivity.date.slot.start
              } - ${registeractivity.date.slot.end}</strong>. `
        "
      ></p>

      <k-spacing y="8"></k-spacing>

      <v-row>
        <v-col cols="12" :class="!is_mobile ? 'd-flex' : ''">
          <v-btn
            class="btn text-white quickfix-btn"
            :block="is_mobile"
            color="var(--company-primary)"
            @click="$emit('close-dialog'), (group_time_dialog = false)"
          >
            {{ $i18n.locale == "nl" ? "Afsluiten" : "Close" }}
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <div v-if="scrollable" :class="['mobile-scroll-btn']">
      <v-btn
        color="#353535E5"
        class="text-white"
        icon
        @click="scroll"
        size="lg"
      >
        <v-icon>mdi-chevron-down</v-icon>
      </v-btn>
    </div>
  </v-dialog>
</template>

<script>
import { computed } from "vue"; // Import computed from Vue's reactivity package
import ICAL from "ical.js";
import { useMainStore } from "../../stores/store";
import api from "../../services/api";
import config from "../../config";
import UserenrollsDialog from "./userenrollsDialog.vue";
import functions from "@/util/functions";

export default {
  components: { UserenrollsDialog },

  props: {
    activity_id: Number,
    enroll: Object,
    page: Object,
  },
  data: () => ({
    mainStore: useMainStore(),
    selectedLocations: [],
    selectedDates: [],
    functions: functions,
    ICAL: ICAL,
    slotId: null,
    user_dialog: false,
    step: 1,
    icflink: {},
    sent: false,
    scrollable: false,
    scrollTop: 0, // Tracks the current scroll position
    activity: {
      title: "",
      locations: [],
      content: {
        nl: {
          body: {},
          banner: {},
        },
        eng: {
          body: {},
          banner: {},
        },
      },
    },
    registration: {
      remark: "",
      colleagues: [],
      reminder: true,
    },
    registeractivity: {
      reminder: true,

      date: {
        slot: {},
      },
    },
    registeractivities: [],
    time_dialog: false,
    credits: {},
    period: null,
  }),
  mounted() {},
  watch: {
    "registeractivity.location": function () {
      this.checkcredits();
    },
  },
  created() {
    this.getActivity();
  },
  computed: {
    is_mobile() {
      return window.innerWidth <= 768;
    },
    groupDates() {
      const dateMap = new Map();
      var location = this.locationIndex;
      // Iterate through each location and its specialists.
      this.activity.locations[location].specialists.forEach(
        ({ name, id, dates }) => {
          dates.forEach(({ date, slots }) => {
            // Format the date to ensure consistent comparison (assuming date is a Date object or string)
            const formattedDate = new Date(date).toISOString().split("T")[0];
            slots.forEach((slot) => {
              // Check if there are entries for this date, if not initialize an array
              if (!dateMap.has(formattedDate)) {
                dateMap.set(formattedDate, []);
              }
              // Append each slot to the date's array
              dateMap.get(formattedDate).push({
                date: date,
                specialist: {
                  id,
                  location,
                  name,
                  slot, // Add individual slot here
                },
              });
            });
          });
        }
      );

      // Flatten the entries for output, no need for mapping to the first element of arrays
      var groupedDatesArray = Array.from(dateMap.values()).flat();

      // Sort the array based on the 'date' property
      groupedDatesArray.sort((a, b) => {
        // Convert the 'date' strings to Date objects for comparison
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);
        return dateA - dateB;
      });

      return groupedDatesArray;
    },
    groupDatesFiltered() {
      const dateMap = new Map();
      var location = this.locationIndex;

      // Group existing selected dates by period for credit comparison
      const datesGroupedByPeriod = {};
      const periodStartDate = this.getPeriodStartDate(
        this.registeractivity.date.date,
        this.period
      );
      if (!datesGroupedByPeriod[periodStartDate]) {
        datesGroupedByPeriod[periodStartDate] = [];
      }
      datesGroupedByPeriod[periodStartDate].push(
        this.registeractivity.date.date
      );

      // Convert enroll.date from epoch to ISO date string
      const enrollDate = new Date(this.enroll.date * 1000)
        .toISOString()
        .split("T")[0];

      // Iterate through each location and its specialists.
      this.activity.locations[location].specialists.forEach(
        ({ name, id, dates }) => {
          dates.forEach(({ date, slots }) => {
            // Format the date to ensure consistent comparison
            const formattedDate = new Date(date).toISOString().split("T")[0];
            const periodStartDate = this.getPeriodStartDate(
              formattedDate,
              this.period
            );

            const datesInPeriod = datesGroupedByPeriod[periodStartDate] || [];
            // If enroll date matches, increment the credit for that period
            if (formattedDate === enrollDate) {
              this.credits[periodStartDate] =
                (this.credits[periodStartDate] || 0) + 1;
            }

            const isDisabled =
              datesInPeriod.length >= (this.credits[periodStartDate] || 0) &&
              this.getPeriodStartDate(formattedDate, this.period) !=
                this.getPeriodStartDate(enrollDate, this.period);

            slots.forEach((slot) => {
              const alreadySelected =
                this.registeractivity.date.date == date &&
                slot.id == this.registeractivity.date.slot.id;

              // Check if there are entries for this date, if not initialize an array
              if (!dateMap.has(formattedDate)) {
                dateMap.set(formattedDate, []);
              }
              // Append each slot to the date's array
              dateMap.get(formattedDate).push({
                date: date,
                specialist: {
                  id,
                  location,
                  name,
                  slot, // Add individual slot here
                },
                disabled: isDisabled && !alreadySelected ? isDisabled : false, // Mark as disabled based on credit check
              });
            });
          });
        }
      );

      // Flatten the entries for output
      var groupedDatesArray = Array.from(dateMap.values()).flat();

      // Sort the array based on the 'date' property
      groupedDatesArray.sort((a, b) => {
        // Convert the 'date' strings to Date objects for comparison
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);
        return dateA - dateB;
      });

      return groupedDatesArray;
    },
    configdata() {
      return config;
    },
    locationIndex() {
      return this.activity.locations.findIndex(
        (location) => location.id === this.registeractivity.location
      );
    },
    specialistIndex() {
      return this.activity.locations[this.locationIndex].specialists.findIndex(
        (specialist) => specialist.id === this.registeractivity.specialist
      );
    },
    icsDownloadLink: computed(() => this.icsLink()),
  },

  methods: {
    getClassForDate(item) {
      let class_thing = "group-date-card";

      // Check if the selected registeractivity.date.date matches the item's specialist slot ID
      if (
        this.registeractivity.date &&
        this.registeractivity.date.slot.id === item.specialist.slot.id
      ) {
        class_thing += " selected-date";
      }

      // Check if the item is disabled or if it matches a registered date using checkDate function
      if (
        (item.disabled &&
          (!this.registeractivity.date ||
            this.registeractivity.date.slot.id !== item.specialist.slot.id)) ||
        functions.checkDate(item.date, item.specialist.slot.start)
      ) {
        class_thing += " disabled-card";
      }

      return class_thing;
    },

    checkOverflow() {
      setTimeout(() => {
        const form = this.$refs[`step${this.step}Form`];
        if (form) {
          const el = form.$el || form; // Handle Vuetify components
          const isOverflowing =
            el.scrollHeight > el.clientHeight &&
            el.scrollHeight - el.clientHeight > 100;
          this.scrollable = isOverflowing;
        } else {
          this.scrollable = false; // Default if no overflow
        }
      }, 100); // Delay of 1 second
    },
    scroll() {
      const form = this.$refs[`step${this.step}Form`];

      // Scroll down the active form
      if (form) {
        const el = form.$el || form;
        el.scrollBy({
          top: 100, // Adjust as needed
          behavior: "smooth",
        });
      }
    },
    selectEnroll(id) {
      this.slotId = id;
      this.user_dialog = true;
    },
    getPeriodStartDate(date, period) {
      const dt = new Date(date);
      if (period === "week") {
        if (dt.getDay() === 0) {
          // If it's Sunday
          dt.setDate(dt.getDate() - 6); // Move back to the previous Monday
        } else {
          // For Monday to Saturday
          dt.setDate(dt.getDate() - (dt.getDay() - 1)); // Adjust to the current week's Monday
        }
      } else if (period === "month") {
        dt.setDate(1); // Set to the first day of the month
      } else if (period === "year") {
        dt.setMonth(0, 1); // Set to the first day of the year
      }
      // Manual formatting to ensure local date:
      return (
        dt.getFullYear() +
        "-" +
        ("0" + (dt.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + dt.getDate()).slice(-2)
      );
    },
    checkcredits() {
      var dateObject = {};
      var newDates = this.groupDates;
      var newDatesArray = [];
      newDates.forEach((element) => {
        newDatesArray.push(element.date);
      });
      dateObject.dates = newDatesArray;

      api
        .post(
          `${config.url.api}/v1/activities/${this.page.id}/checkcredits`,
          dateObject
        )
        .then((res) => {
          this.credits = res.data.dates;
          this.period = res.data.period;
        });
    },
    getActivity() {
      api
        .get(`${config.url.api}/v1/activities/${this.activity_id}/client`)
        .then((res) => {
          this.activity = res.data.data;
          this.activity.locations.forEach((location) => {
            location.specialists.forEach((specialist) => {
              specialist.dates = specialist.dates.map((date) => {
                // Convert the ISO string to a Date object first
                const dateObject = new Date(date.date * 1000);

                // Then format it to 'YYYY-MM-DD'
                // This ensures compatibility with the Vuetify date picker

                return {
                  ...date, // Keep the existing properties of the date object
                  date: dateObject, // Replace the date with the formatted one
                };
              });
            });
            this.registeractivity.location = this.enroll.location;
            this.registeractivity.specialist = this.enroll.activity_specialist;
          });
        })
        .catch(() => {});
    },
    selectItem(item) {
      // If the item is not found, construct a new object and add it to the array
      var pushItem = {
        date: item.date,
        slot: item.specialist.slot, // Assuming the first slot is always the desired one
        specialist: item.specialist.name,
      };
      this.registeractivity.date = pushItem;
    },
    filteredSpecialists(specialists) {
      // Check if the number of specialists is less than 5
      if (specialists.length < 5) {
        // If yes, return all specialists
        return specialists;
      } else {
        // If there are 5 or more, return only the first 5 specialists
        return specialists.slice(0, 5);
      }
    },
    filteredLocations() {
      let filteredLocations = [];
      // Iterate over each location
      for (let location of this.activity.locations) {
        let dateMap = new Map();
        // Iterate through each specialist and their dates in the current location
        location.specialists.forEach(({ dates }) => {
          dates.forEach(({ date }) => {
            const formattedDate = new Date(date).toISOString().split("T")[0];
            if (!dateMap.has(formattedDate)) {
              dateMap.set(formattedDate, true);
            }
          });
        });

        // If the dateMap has any entries, it means this location has specialists with dates
        if (dateMap.size > 0) {
          filteredLocations.push(location);
        }
      }
      if (filteredLocations.length == 1) {
        this.registeractivity.location = filteredLocations[0].id;
      }
      // Further filter based on the original logic if necessary
      return filteredLocations;
    },
    truncateContent(content) {
      const words = content.split(" ");
      if (words.length > 10) {
        const truncated = words.slice(0, 10).join(" ") + "...";
        return truncated;
      } else {
        return content;
      }
    },
    sliceDates(index) {
      this.selectedDates.splice(index, 1);
      this.registeractivities.splice(index, 1);
    },

    changeLocation(key) {
      this.registeractivities[key].specialist = null;
    },

    getListActivityLocationSpecialistSlots(date, location, specialist, index) {
      let activitydate = null;

      this.activity.schedule.dates.forEach((schedule_date) => {
        if (schedule_date.date * 1000 == date) {
          activitydate = schedule_date;
        }
      });
      if (activitydate.slots[location][specialist].length == 1) {
        this.registeractivities[index].slot =
          activitydate.slots[location][specialist][0];
      }
      return activitydate.slots[location][specialist] != undefined
        ? activitydate.slots[location][specialist]
        : [];
    },

    addColleague() {
      this.registeractivity.colleagues.push({
        email: "",
      });
    },
    register() {
      this.registeractivity.title = this.activity.content.nl.banner.title;
      if (!this.sent) {
        this.registeractivity.enroll_id = this.enroll.enroll_id;
        this.$emit("update-enroll", this.registeractivity);
        this.sent = true;
        this.step = 4;
      }
    },

    closeDialog() {
      this.$emit("close-dialog");
      this.$router.push("/bedrijfspagina");
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    formatDay(epochDate) {
      // Convert epoch date to a Dutch day string (e.g., "donderdag" for Thursday)
      const dateObject = new Date(epochDate * 1000);
      const options = { weekday: "long", timeZone: "UTC" };
      return dateObject.toLocaleDateString("nl-NL", options);
    },
    formatDate(epochDate) {
      // Convert epoch date to a formatted date string (ignoring the time)
      const dateObject = new Date(epochDate);
      const year = dateObject.getFullYear();
      const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
      const day = dateObject.getDate().toString().padStart(2, "0");
      return `${year}-${month}-${day}`;
    },

    customAllowedDates(date) {
      // Iterate through each location and its specialists.
      for (const { specialists } of this.activity.locations) {
        for (const { id, dates } of specialists) {
          for (const { date: specialistDate, slots } of dates) {
            // Check if the date matches and if the activity's specialist matches this specialist's id

            if (
              specialistDate.toISOString().split("T")[0] ===
                date.toISOString().split("T")[0] &&
              this.registeractivity.specialist === id &&
              slots.length > 0
            ) {
              return true; // Match found, return true
            }
          }
        }
      }

      // If no match is found after iterating through all, return false
      return false;
    },
    filterSlots(date) {
      // Iterate through each location and its specialists.

      for (const { date: specialistDate, slots } of this.activity.locations[
        this.locationIndex
      ].specialists[this.specialistIndex].dates) {
        // Check if the date matches and if the given specialist matches this specialist's id
        if (
          specialistDate.toISOString().split("T")[0] ===
          date.toISOString().split("T")[0]
        ) {
          return slots; // Match found, return slots
        }
      }

      // If no match is found after iterating through all, return an empty array or null
      return null;
    },

    deleteitem() {},
  },
};
</script>
