<template>
  <v-dialog
    v-model="single_time_dialog"
    width="500"
    content-class="card pa-3 pb-6  pa-md-7 register-dialog"
  >
    <v-btn
      icon
      dark
      variant="text"
      class="dialog-close-btn"
      @click="$emit('close-dialog')"
    >
      <v-icon>mdi-close</v-icon>
    </v-btn>

    <v-form
      v-if="step === 1"
      class="overflow-y-scroll pt-3 pb-3"
      ref="step1Form"
    >
      <div v-if="step === 1" class="stepper-view px-3">
        <span class="step-nr">{{
          $i18n.locale == "nl" ? "Stap 1/4" : "Step 1/4"
        }}</span>
        <k-spacing y="8"></k-spacing>
        <p class="step-subtitle">
          {{
            $i18n.locale == "nl" ? "Wijzig aanmelding" : "Change registration"
          }}
        </p>

        <h3 class="step-title mb-4 mt-1">
          {{
            $i18n.locale == "nl"
              ? activity.content.nl.banner.title
              : activity.content.eng.banner.title
          }}
          <v-chip class="" color="var(--company-primary)" variant="tonal">
            {{ $i18n.locale == "nl" ? "1 OP 1" : "1 ON 1" }}
          </v-chip>
        </h3>

        <span class="enroll-date">
          {{
            new Date(enroll.date * 1000)
              .toLocaleDateString("nl-NL", {
                day: "numeric",
                month: "short",
                year: "numeric",
              })
              .replace(/(\w{3})/, "$1.")
          }}
          {{ enroll.start }} -
          {{ enroll.end }}
        </span>

        <k-spacing y="6"></k-spacing>
        <label class="input-label" for="choose-settling">{{
          $t("components.changeActivityDialogDialog.dialog_stepper_branche_l")
        }}</label>
        <v-select
          class="mt-1"
          v-model="registeractivity.location"
          :items="filterLocations(activity.locations)"
          item-value="id"
          item-title="data.city"
          density="compact"
          variant="solo"
          :placeholder="
            $t(
              'components.changeActivityDialogDialog.dialog_stepper_branche_ph'
            )
          "
          name="choose-settling"
          validate-on-blur
        />

        <k-spacing v-if="!is_mobile" y="8"></k-spacing>
        <k-spacing v-else y="4"></k-spacing>
        <v-row>
          <v-col cols="12" :class="!is_mobile ? 'd-flex' : ''">
            <v-btn
              v-if="is_mobile"
              :block="is_mobile"
              :disabled="!registeractivity.location"
              class="btn text-white quickfix-btn"
              color="var(--company-primary)"
              @click="step = 2"
            >
              {{ $i18n.locale == "nl" ? "Ga verder" : "Continue" }}
            </v-btn>
            <k-spacing y="4" v-if="is_mobile"></k-spacing>

            <v-btn
              :block="is_mobile"
              class="btn btn-company-primary quickfix-btn"
              color="var(--company-primary)"
              variant="outlined"
              @click="$emit('close-dialog')"
            >
              {{ $t("components.changeActivityDialogDialog.dialog_close_btn") }}
            </v-btn>
            <k-spacing x="4"></k-spacing>
            <v-btn
              v-if="!is_mobile"
              :disabled="!registeractivity.location"
              class="btn text-white quickfix-btn"
              color="var(--company-primary)"
              @click="step = 2"
            >
              {{ $i18n.locale == "nl" ? "Ga verder" : "Continue" }}
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </v-form>
    <v-form
      v-if="step === 2"
      class="overflow-y-scroll pt-3 pb-3"
      ref="step2Form"
    >
      <div v-if="step === 2" class="stepper-view px-3">
        <span class="step-nr">{{
          $t("components.changeActivityDialogDialog.dialog_stepper_title")
        }}</span>
        <k-spacing y="8"></k-spacing>
        <p class="step-subtitle">
          {{
            $i18n.locale == "nl" ? "Wijzig aanmelding" : "Change registration"
          }}
        </p>
        <h3 class="step-title mb-4 mt-1" v-if="$i18n.locale == 'nl'">
          {{
            $i18n.locale == "nl"
              ? activity.content.nl.banner.title
              : activity.content.eng.banner.title
          }}
          <v-chip class="" color="var(--company-primary)" variant="tonal">
            {{ $i18n.locale == "nl" ? "1 OP 1" : "1 ON 1" }}
          </v-chip>
        </h3>
        <span class="enroll-date">
          {{
            new Date(enroll.date * 1000)
              .toLocaleDateString("nl-NL", {
                day: "numeric",
                month: "short",
                year: "numeric",
              })
              .replace(/(\w{3})/, "$1.")
          }}
          {{ enroll.start }} -
          {{ enroll.end }}
        </span>
        <k-spacing y="4"></k-spacing>
        <label class="input-label" for="choose-settling">{{
          $t(
            "components.changeActivityDialogDialog.dialog_stepper_specialist_l"
          )
        }}</label>
        <div class="choose-freelancer-card mt-1">
          <v-list class="w-100 pa-0 pt-2">
            <v-list-item
              :class="
                registeractivity.specialist == item.id
                  ? 'selected-freelancer mb-2'
                  : 'not-selected-freelancer mb-2'
              "
              @click="registeractivity.specialist = item.id"
              v-for="(item, index) in filterSpecialists(
                activity.locations[locationIndex].specialists
              )"
              :key="index"
            >
              <div class="d-flex">
                <v-avatar
                  color="var(--company-primary)"
                  size="40"
                  :image="`${configdata.url.cdn}/specialists/avatars/${item.profilepicture}`"
                ></v-avatar>
                <k-spacing x="4"></k-spacing>
                <span class="register-user-text my-auto">
                  {{ truncateContent(item.name) }}
                </span>
              </div>
            </v-list-item>
          </v-list>
        </div>

        <k-spacing y="8"></k-spacing>
        <v-row>
          <v-col cols="12" :class="!is_mobile ? 'd-flex' : ''">
            <v-btn
              v-if="is_mobile"
              :block="is_mobile"
              :disabled="!registeractivity.specialist"
              class="btn text-white quickfix-btn"
              color="var(--company-primary)"
              @click="step = 3"
            >
              {{ $i18n.locale == "nl" ? "Ga verder" : "Continue" }}
            </v-btn>
            <k-spacing y="4" v-if="is_mobile"></k-spacing>

            <v-btn
              :block="is_mobile"
              class="btn btn-company-primary quickfix-btn"
              color="var(--company-primary)"
              variant="outlined"
              @click="step -= 1"
            >
              {{ $t("components.changeActivityDialogDialog.dialog_prev_btn") }}
            </v-btn>
            <k-spacing x="4"></k-spacing>
            <v-btn
              v-if="!is_mobile"
              :disabled="!registeractivity.specialist"
              class="btn text-white quickfix-btn"
              color="var(--company-primary)"
              @click="step = 3"
            >
              {{ $i18n.locale == "nl" ? "Ga verder" : "Continue" }}
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </v-form>
    <v-form
      class="overflow-y-scroll pt-3 pb-3"
      v-if="step === 3"
      ref="step3Form"
    >
      <div v-if="step === 3" class="stepper-view px-3">
        <span class="step-nr">{{
          $i18n.locale == "nl" ? "Stap 3/4" : "Step 3/4"
        }}</span>
        <k-spacing y="8"></k-spacing>
        <p class="step-subtitle">
          {{
            $i18n.locale == "nl" ? "Wijzig aanmelding" : "Change registration"
          }}
        </p>
        <h3 class="step-title mb-4 mt-1" v-if="$i18n.locale == 'nl'">
          {{
            $i18n.locale == "nl"
              ? activity.content.nl.banner.title
              : activity.content.eng.banner.title
          }}
          <v-chip class="" color="var(--company-primary)" variant="tonal">
            {{ $i18n.locale == "nl" ? "1 OP 1" : "1 ON 1" }}
          </v-chip>
        </h3>
        <span class="enroll-date">
          {{
            new Date(enroll.date * 1000)
              .toLocaleDateString("nl-NL", {
                day: "numeric",
                month: "short",
                year: "numeric",
              })
              .replace(/(\w{3})/, "$1.")
          }}
          {{ enroll.start }} -
          {{ enroll.end }}
        </span>
        <k-spacing y="8"></k-spacing>
        <label class="input-label mb-4" for="choose-date">{{
          $t("components.changeActivityDialogDialog.dialog_date_l")
        }}</label>
        <v-date-picker
          hide-header
          :allowed-dates="customAllowedDates"
          class="date-picker-card"
          v-model="selectedDate"
          name="choose-date"
          :title="
            $t('components.changeActivityDialogDialog.dialog_date_ph_text')
          "
          :text="
            $t('components.changeActivityDialogDialog.dialog_date_ph_text')
          "
          color="var(--company-primary)"
        ></v-date-picker>
        <k-spacing y="4" />
        <label class="input-label" for="choose-date">{{
          $t("components.changeActivityDialogDialog.dialog_selected_date_l")
        }}</label>
        <div v-if="registeractivity.date.date">
          <div class="d-flex">
            <p>
              {{ capitalizeFirstLetter(formatDay(registeractivity.date.date)) }}
            </p>
            <v-btn
              icon
              dark
              variant="text"
              class="ml-auto date-splice-button"
              @click="
                (registeractivity.date = {
                  date: null,
                  slot: {},
                }),
                  (selectedDate = null)
              "
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
          <div>
            <p class="text-bold">
              {{
                new Date(registeractivity.date.date)
                  .toLocaleDateString("nl-NL", {
                    day: "numeric",
                    month: "short",
                    year: "numeric",
                  })
                  .replace(/(\w{3})/, "$1.")
              }}
            </p>
          </div>
          <div
            class="mt-2"
            v-for="(slots, specialistName) in filterSlots(
              registeractivity.date.date
            )"
            :key="specialistName"
          >
            <h6>{{ specialistName }}</h6>
            <!-- Display the specialist's name -->
            <v-chip-group
              variant="flat"
              selected-class="v-chip--selected"
              column
              v-model="registeractivity.date.slot"
            >
              <div v-for="(item, cindex) in slots" :key="cindex">
                <v-chip
                  class="time-chip"
                  color="var(--company-primary)"
                  :value="item"
                  label
                  v-if="item.type !== 'break' && !item.full"
                >
                  <span>{{ item.start }} - {{ item.end }}</span>
                </v-chip>
              </div>
            </v-chip-group>
          </div>
        </div>

        <k-spacing y="8"></k-spacing>
        <v-row>
          <v-col cols="12" :class="!is_mobile ? 'd-flex' : ''">
            <v-btn
              v-if="is_mobile"
              :block="is_mobile"
              class="btn text-white quickfix-btn"
              color="var(--company-primary)"
              :disabled="!selectedDate || !registeractivity.date.slot"
              @click="step = 4"
            >
              {{ $i18n.locale == "nl" ? "Ga verder" : "Continue" }}
            </v-btn>
            <k-spacing y="4" v-if="is_mobile"></k-spacing>

            <v-btn
              :block="is_mobile"
              class="btn btn-company-primary quickfix-btn"
              color="var(--company-primary)"
              variant="outlined"
              @click="previousStep()"
            >
              {{ $t("components.changeActivityDialogDialog.dialog_prev_btn") }}
            </v-btn>
            <k-spacing x="4"></k-spacing>
            <k-spacing y="4" v-if="is_mobile"></k-spacing>

            <v-btn
              v-if="!is_mobile"
              class="btn text-white quickfix-btn"
              color="var(--company-primary)"
              :disabled="!selectedDate || !registeractivity.date.slot"
              @click="step = 4"
            >
              {{ $i18n.locale == "nl" ? "Ga verder" : "Continue" }}
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </v-form>

    <div
      v-if="step === 4"
      class="stepper-view px-3 overflow-y-scroll pt-3 pb-3"
    >
      <span class="step-nr">{{
        $t("components.changeActivityDialogDialog.dialog_stepper_title_4")
      }}</span>
      <k-spacing y="8"></k-spacing>
      <p class="step-subtitle">
        {{ $i18n.locale == "nl" ? "Wijzig aanmelding" : "Change registration" }}
      </p>
      <h3 class="step-title mb-4 mt-1" v-if="$i18n.locale == 'nl'">
        {{
          $i18n.locale == "nl"
            ? activity.content.nl.banner.title
            : activity.content.eng.banner.title
        }}
        <v-chip class="" color="var(--company-primary)" variant="tonal">
          {{ $i18n.locale == "nl" ? "1 OP 1" : "1 ON 1" }}
        </v-chip>
      </h3>
      <span class="enroll-date">
        {{
          new Date(enroll.date * 1000)
            .toLocaleDateString("nl-NL", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })
            .replace(/(\w{3})/, "$1.")
        }}
        {{ enroll.start }} -
        {{ enroll.end }}
      </span>

      <k-spacing y="8"></k-spacing>
      <label class="input-label" for="choose-specialist">{{
        $i18n.locale == "nl" ? "Aanmeld gegevens" : "Registration details"
      }}</label>
      <k-spacing y="4"></k-spacing>
      <div v-if="registeractivity.date.date">
        <v-row class="lh-fix card-light summary-cards pt-2">
          <v-col
            v-if="registeractivity.date.slot.location_type != 'online'"
            cols="12"
            md="3"
          >
            <span class="register-card-summary-text">
              {{
                $t(
                  "components.registerActivityDialog.dialog_stepper_title_4_l_summary_1"
                )
              }}
            </span>
          </v-col>
          <v-col
            v-if="registeractivity.date.slot.location_type != 'online'"
            cols="12"
            md="9"
          >
            <span class="register-card-summary-answer">
              {{ activity.locations[locationIndex].data.city }}
            </span>
          </v-col>
          <v-col
            v-if="registeractivity.date.slot.location_type == 'online'"
            cols="12"
            md="3"
            class="mt-1"
          >
            <span class="register-card-summary-text"> Link </span>
          </v-col>
          <v-col
            v-if="registeractivity.date.slot.location_type == 'online'"
            cols="12"
            md="9"
          >
            <span class="register-card-summary-answer">
              <a
                :href="registeractivity.date.slot.address.meeting_link"
                target="_blank"
                >{{ registeractivity.date.slot.address.meeting_link }}</a
              >
            </span>
          </v-col>
          <v-col
            v-if="registeractivity.date.slot.location_type == 'online'"
            cols="12"
            md="3"
            class="mt-1"
          >
            <span class="register-card-summary-text"> Meeting ID </span>
          </v-col>
          <v-col
            v-if="registeractivity.date.slot.location_type == 'online'"
            cols="12"
            md="9"
          >
            <span class="register-card-summary-answer">
              {{ registeractivity.date.slot.address.meeting_id }}
            </span>
          </v-col>
          <v-col
            v-if="registeractivity.date.slot.location_type == 'online'"
            cols="12"
            md="3"
            class="mt-1"
          >
            <span class="register-card-summary-text"> Passcode </span>
          </v-col>
          <v-col
            v-if="registeractivity.date.slot.location_type == 'online'"
            cols="12"
            md="9"
          >
            <span class="register-card-summary-answer">
              {{ registeractivity.date.slot.address.passcode }}
            </span>
          </v-col>
          <v-col
            cols="12"
            md="3"
            class="mt-1"
            v-if="
              registeractivity.date.slot.location_type != 'online' &&
              registeractivity.date.slot.address.room
            "
          >
            <span class="register-card-summary-text">
              {{
                $t(
                  "components.registerActivityDialog.dialog_stepper_title_4_l_summary_2"
                )
              }}
            </span>
          </v-col>
          <v-col
            v-if="
              registeractivity.date.slot.location_type != 'online' &&
              registeractivity.date.slot.address.room
            "
            cols="12"
            md="9"
          >
            <span class="register-card-summary-answer">
              {{ registeractivity.date.slot.address.room }}
            </span>
          </v-col>
          <v-col cols="12" md="3" class="mt-1">
            <span class="register-card-summary-text">
              {{
                $t(
                  "components.registerActivityDialog.dialog_stepper_title_4_l_summary_3"
                )
              }}
            </span>
          </v-col>
          <v-col cols="12" md="9">
            <span class="register-card-summary-answer">
              {{
                activity.locations[locationIndex].specialists[
                  getSpecialistIndex(
                    registeractivity.date.slot.activity_specialist
                  )
                ].name
              }}
            </span>
          </v-col>

          <v-col cols="12" md="3" class="mt-1">
            <span class="register-card-summary-text">
              {{
                $t(
                  "components.registerActivityDialog.dialog_stepper_title_4_l_summary_4"
                )
              }}
            </span>
          </v-col>
          <v-col cols="12" md="9">
            <span class="register-card-summary-answer">
              {{
                new Date(registeractivity.date.date)
                  .toLocaleDateString("nl-NL", {
                    day: "numeric",
                    month: "short",
                    year: "numeric",
                  })
                  .replace(/(\w{3})/, "$1.")
              }}
            </span>
          </v-col>

          <v-col cols="12" md="3" class="mt-1">
            <span class="register-card-summary-text">
              {{
                $t(
                  "components.registerActivityDialog.dialog_stepper_title_4_l_summary_5"
                )
              }}
            </span>
          </v-col>
          <v-col cols="12" md="9">
            <span class="register-card-summary-answer">
              {{ registeractivity.date.slot.start }} -
              {{ registeractivity.date.slot.end }}
            </span>
          </v-col>
        </v-row>
        <k-spacing y="4"></k-spacing>
      </div>

      <v-checkbox
        class="ml-n2"
        :label="
          $t('components.changeActivityDialogDialog.dialog_stepper_checkbox_l')
        "
        color="var(--company-primary)"
        v-model="registeractivity.reminder"
      >
      </v-checkbox>
      <k-spacing y="4"></k-spacing>
      <v-row>
        <v-col cols="12" :class="!is_mobile ? 'd-flex' : ''">
          <v-btn
            :block="is_mobile"
            class="btn btn-company-primary quickfix-btn"
            color="var(--company-primary)"
            variant="outlined"
            @click="step -= 1"
          >
            {{ $t("components.changeActivityDialogDialog.dialog_prev_btn") }}
          </v-btn>
          <k-spacing x="4"></k-spacing>
          <k-spacing y="4" v-if="is_mobile"></k-spacing>

          <v-btn
            :block="is_mobile"
            class="btn text-white quickfix-btn"
            @click="register(), checkOverflow()"
            color="var(--company-primary)"
          >
            {{ $i18n.locale == "nl" ? "Ik meld mij aan" : "Submit" }}
          </v-btn>
        </v-col>
      </v-row>
    </div>

    <div class="stepper-view px-3 pb-3" v-if="step === 5">
      <div class="banner-activity-thnx-img">
        <img :src="`${configdata.url.cdn}/activities/${activity.image}`" />
      </div>
      <k-spacing y="8"></k-spacing>
      <h3 class="step-title">
        {{
          $i18n.locale == "nl"
            ? "Wijziging is gelukt!"
            : "Change is successful!"
        }}
      </h3>
      <k-spacing y="4"></k-spacing>

     
      <p
        v-html="
          $i18n.locale == 'nl'
            ? `Jouw aanmelding is succesvol verplaatst naar  <strong>${new Date(registeractivity.date.date)
                .toLocaleDateString('nl-NL', {
                  day: 'numeric',
                  month: 'short',
                  year: 'numeric',
                })
                .replace(/(\w{3})/, '$1.')}</strong>, <strong>${
                registeractivity.date.slot.start
              } - ${
                registeractivity.date.slot.end
              }</strong>.`
            : `Your enrollment has been successfully moved to <strong>${new Date(registeractivity.date.date)
                .toLocaleDateString('nl-NL', {
                  day: 'numeric',
                  month: 'short',
                  year: 'numeric',
                })
                .replace(/(\w{3})/, '$1.')}</strong>, <strong>${
                registeractivity.date.slot.start
              } - ${
                registeractivity.date.slot.end
              }</strong>. `
        "
      ></p>
      <k-spacing y="8"></k-spacing>

      <v-row>
        <v-col cols="12" :class="!is_mobile ? 'd-flex' : ''">
          <v-btn
            class="btn text-white quickfix-btn"
            :block="is_mobile"
            color="var(--company-primary)"
            @click="$emit('close-dialog'), (single_time_dialog = false)"
          >
            {{ $i18n.locale == "nl" ? "Afsluiten" : "Close" }}
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <div v-if="scrollable" :class="['mobile-scroll-btn']">
      <v-btn
        color="#353535E5"
        class="text-white"
        icon
        @click="scroll"
        size="lg"
      >
        <v-icon>mdi-chevron-down</v-icon>
      </v-btn>
    </div>
  </v-dialog>
</template>

<script>
import { computed } from "vue"; // Import computed from Vue's reactivity package
import ICAL from "ical.js";
import { useMainStore } from "../../stores/store";
import api from "../../services/api";
import config from "../../config";
export default {
  components: {},

  props: {
    activity_id: Number,
    enroll: Object,
    page: Object,
  },
  data: () => ({
    mainStore: useMainStore(),
    selectedLocations: [],
    selectedDate: null,
    ICAL: ICAL,
    step: 1,
    ready: false,
    scrollable: false,
    scrollTop: 0, // Tracks the current scroll position
    icflink: {},
    activity: {
      title: "",
      locations: [],
      content: {
        nl: {
          body: {},
          banner: {},
        },
        eng: {
          body: {},
          banner: {},
        },
      },
    },
    sent: false,

    registration: {
      remark: "",
      colleagues: [],
      reminder: true,
    },
    registeractivity: {
      reminder: true,
      date: {},
      colleagues: [],
    },
    firstTime: false,
    credits: {},
    period: null,
    registeractivities: [],
    single_time_dialog: false,
  }),
  created() {
    this.getActivity();
  },
  mounted() {},

  computed: {
    is_mobile() {
      return window.innerWidth <= 768;
    },
    configdata() {
      return config;
    },
    locationIndex() {
      return this.activity.locations.findIndex(
        (location) => location.id === this.registeractivity.location
      );
    },
    specialistIndex() {
      return this.activity.locations[this.locationIndex].specialists.findIndex(
        (specialist) => specialist.id === this.registeractivity.specialist
      );
    },
    icsDownloadLink: computed(() => this.icsLink()),
  },
  watch: {
    "registeractivity.specialist": function () {
      this.checkcredits();
    },
    "registeractivity.date": function () {
      // This will trigger whenever the specialist changes
      if (!this.registeractivity.date.date) {
        this.findFirstAllowedDate();
      }
    },
    selectedDate() {
      // Add new date to activity.dates
      if (this.selectedDate && !this.firstTime) {
        this.registeractivity.date = {
          date: this.selectedDate,
          slot: null,
        };
      } else {
        this.registeractivity.date.date = this.selectedDate;
      }
      if (!this.selectedDate) {
        this.findFirstAllowedDate();
      }
    },
  },
  methods: {
    findFirstAllowedDate() {
      let date = new Date();
      date.setHours(0, 0, 0, 0); // Ensure time is set to midnight

      // Convert PHP epoch (this.enroll.date) to JavaScript Date object
      const enrollDate = new Date(this.enroll.date * 1000); // Multiply by 1000 to convert seconds to milliseconds
      enrollDate.setHours(0, 0, 0, 0);

      for (let i = 0; i < 365; i++) {
        if (this.customAllowedDates(date)) {
          // Format the date to YYYY-MM-DD (ISO format)
          const formattedInputDate = date
            .toLocaleDateString("en-CA", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            })
            .replace(/(\d{2})\/(\d{2})\/(\d{4})/, "$3-$1-$2");

          // Get the period start date for the given date
          const periodStartDate = this.getPeriodStartDate(
            formattedInputDate,
            this.period
          );

          // If the enrollDate falls within this period, increment the credits
          if (date.getTime() === enrollDate.getTime()) {
            if (this.credits[periodStartDate] !== undefined) {
              this.credits[periodStartDate] += 1;
            } else {
              this.credits[periodStartDate] = 1; // Initialize if not set
            }
          }

          if (this.credits[periodStartDate] > 0) {
            // The date is allowed and passes the credit check
            this.selectedDate = new Date(date); // Ensure it's a proper Date object
            this.registeractivity.date = {
              date: new Date(date),
              slot: null,
            };
            break;
          }
        }
        // Move to the next day
        date.setDate(date.getDate() + 1);
      }
    },

    checkOverflow() {
      setTimeout(() => {
        const form = this.$refs[`step${this.step}Form`];
        if (form) {
          const el = form.$el || form; // Handle Vuetify components
          const isOverflowing =
            el.scrollHeight > el.clientHeight &&
            el.scrollHeight - el.clientHeight > 100;
          this.scrollable = isOverflowing;
        } else {
          this.scrollable = false; // Default if no overflow
        }
      }, 100); // Delay of 1 second
    },
    scroll() {
      const form = this.$refs[`step${this.step}Form`];

      // Scroll down the active form
      if (form) {
        const el = form.$el || form;
        el.scrollBy({
          top: 100, // Adjust as needed
          behavior: "smooth",
        });
      }
    },
    formatDay(date) {
      // Convert epoch date to a Dutch day string (e.g., "donderdag" for Thursday)
      const dateObject = new Date(date); // Ensure you're creating a new Date object
      const options = { weekday: "long", timeZone: "Europe/Amsterdam" }; // Use the appropriate time zone
      return dateObject.toLocaleDateString("nl-NL", options);
    },
    getSpecialistIndex(id) {
      return this.activity.locations[this.locationIndex].specialists.findIndex(
        (specialist) => specialist.id === id
      );
    },
    getCustomAllowedDates() {
      const allowedDates = [];

      for (const { id, dates } of this.activity.locations[this.locationIndex]
        .specialists) {
        for (const { date: specialistDate, slots } of dates) {
          const formattedSpecialistDate = new Date(specialistDate)
            .toLocaleDateString("en-CA", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            })
            .replace(/(\d{2})\/(\d{2})\/(\d{4})/, "$3-$1-$2");

          // If the specialist is 'geen_voorkeur', include all dates with slots
          if (
            this.registeractivity.specialist == "geen_voorkeur" &&
            slots.length > 0
          ) {
            allowedDates.push(formattedSpecialistDate);
          }

          // Otherwise, include dates for the selected specialist
          if (this.registeractivity.specialist === id && slots.length > 0) {
            allowedDates.push(formattedSpecialistDate);
          }
        }
      }

      return allowedDates;
    },
    checkcredits() {
      var dateObject = {};
      dateObject.dates = this.getCustomAllowedDates();
      api
        .post(
          `${config.url.api}/v1/activities/${this.page.id}/checkcredits`,
          dateObject
        )
        .then((res) => {
          this.credits = res.data.dates;
          this.period = res.data.period;
          this.findFirstAllowedDate();
        });
    },
    getActivity() {
      api
        .get(`${config.url.api}/v1/activities/${this.activity_id}/client`)
        .then((res) => {
          this.activity = res.data.data;
          this.activity.locations.forEach((location) => {
            location.specialists.forEach((specialist) => {
              specialist.dates = specialist.dates.map((date) => {
                // Convert the ISO string to a Date object first
                const dateObject = new Date(date.date * 1000);

                // Then format it to 'YYYY-MM-DD'
                // This ensures compatibility with the Vuetify date picker

                return {
                  ...date, // Keep the existing properties of the date object
                  date: dateObject, // Replace the date with the formatted one
                };
              });
            });
            this.registeractivity.location = this.enroll.location;
            this.registeractivity.specialist = this.enroll.activity_specialist;

            this.ready = true;
          });
        })
        .catch(() => {});
    },
    previousStep() {
      this.selectedDates = {};
      this.step -= 1;
    },
    filterDates(dates) {
      // Sort the array based on the 'date' property
      dates.sort((a, b) => {
        // Convert the 'date' strings to Date objects for comparison
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);
        return dateA - dateB;
      });

      // Return the sorted array
      return dates;
    },
    filterLocations(locations) {
      const filteredLocations = locations.filter((location) =>
        // Filter locations where at least one specialist has a non-empty dates array
        location.specialists.some(
          (specialist) => specialist.dates && specialist.dates.length > 0
        )
      );

      // Assuming you might also want to sort these filtered locations in some way,
      // you can add sorting logic here. If sorting is not needed, you can remove this comment.
      if (filteredLocations.length == 1) {
        this.registeractivity.location = filteredLocations[0].id;
      }
      // Return the filtered (and potentially sorted) array
      return filteredLocations;
    },
    filterSpecialists(specialists) {
      // Filter and sort specialists
      const filteredSpecialists = specialists
        .filter((specialist) =>
          // Check if there's at least one date with a non-empty slots array
          specialist.dates.some((date) => date.slots && date.slots.length > 0)
        )
        // Sort the filtered specialists alphabetically by name
        .sort((a, b) => {
          const nameA = a.name.toLowerCase();
          const nameB = b.name.toLowerCase();
          return nameA.localeCompare(nameB);
        });

      // Add the "Geen voorkeur" specialist only if there is more than one specialist
      if (filteredSpecialists.length > 1) {
        filteredSpecialists.unshift({
          id: "geen_voorkeur", // Use `null` or a unique identifier for "Geen voorkeur"
          name: this.$i18n.locale == "nl" ? "Geen voorkeur" : "No preference",
          dates: [], // Optional: Set dates as an empty array
        });
      }

      // If only one specialist is found after filtering, set it as the selected specialist
      if (filteredSpecialists.length === 1) {
        this.registeractivity.specialist = filteredSpecialists[0].id;
      }

      // Return the filtered and sorted specialists
      return filteredSpecialists;
    },
    truncateContent(content) {
      const words = content.split(" ");
      if (words.length > 10) {
        const truncated = words.slice(0, 10).join(" ") + "...";
        return truncated;
      } else {
        return content;
      }
    },
    sliceDates(index) {
      this.selectedDates.splice(index, 1);
      this.registeractivities.splice(index, 1);
    },

    changeLocation(key) {
      this.registeractivities[key].specialist = null;
    },

    getListActivityLocationSpecialistSlots(date, location, specialist, index) {
      let activitydate = null;

      this.activity.schedule.dates.forEach((schedule_date) => {
        if (schedule_date.date * 1000 == date) {
          activitydate = schedule_date;
        }
      });
      if (activitydate.slots[location][specialist].length == 1) {
        this.registeractivities[index].slot =
          activitydate.slots[location][specialist][0];
      }
      return activitydate.slots[location][specialist] != undefined
        ? activitydate.slots[location][specialist]
        : [];
    },

    addColleague() {
      this.registeractivity.colleagues.push({
        email: "",
      });
    },
    register() {
      this.registeractivity.title = this.activity.content.nl.banner.title;
      if (!this.sent) {
        this.registeractivity.enroll_id = this.enroll.enroll_id;
        this.sent = true;
        this.$emit("update-enroll", this.registeractivity);
        this.step = 5;
      }
    },

    closeDialog() {
      this.$emit("close-dialog");
      this.$router.push("/bedrijfspagina");
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },

    formatDate(epochDate) {
      // Convert epoch date to a formatted date string (ignoring the time)
      const dateObject = new Date(epochDate);
      const year = dateObject.getFullYear();
      const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
      const day = dateObject.getDate().toString().padStart(2, "0");
      return `${year}-${month}-${day}`;
    },

    getPeriodStartDate(date, period) {
      const dt = new Date(date);
      if (period === "week") {
        if (dt.getDay() === 0) {
          // If it's Sunday
          dt.setDate(dt.getDate() - 6); // Move back to the previous Monday
        } else {
          // For Monday to Saturday
          dt.setDate(dt.getDate() - (dt.getDay() - 1)); // Adjust to the current week's Monday
        }
      } else if (period === "month") {
        dt.setDate(1); // Set to the first day of the month
      } else if (period === "year") {
        dt.setMonth(0, 1); // Set to the first day of the year
      }
      // Manual formatting to ensure local date:
      return (
        dt.getFullYear() +
        "-" +
        ("0" + (dt.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + dt.getDate()).slice(-2)
      );
    },

    customAllowedDates(date) {
      const formattedInputDate = date
        .toLocaleDateString("en-CA", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        })
        .replace(/(\d{2})\/(\d{2})\/(\d{4})/, "$3-$1-$2");

      for (const { id, dates } of this.activity.locations[this.locationIndex]
        .specialists) {
        for (const { date: specialistDate, slots } of dates) {
          const formattedSpecialistDate = new Date(specialistDate)
            .toLocaleDateString("en-CA", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            })
            .replace(/(\d{2})\/(\d{2})\/(\d{4})/, "$3-$1-$2");

          if (
            (formattedSpecialistDate === formattedInputDate &&
              this.registeractivity.specialist === id &&
              slots.length > 0) ||
            (formattedSpecialistDate === formattedInputDate &&
              this.registeractivity.specialist == "geen_voorkeur" &&
              slots.length > 0)
          ) {
            return true; // Date is available
          }
        }
      }
      return false; // Date is not available
    },

    filterSlots(date) {
      // Format the input date to local YYYY-MM-DD
      const formattedInputDate = date
        .toLocaleDateString("en-CA", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        })
        .replace(/(\d{2})\/(\d{2})\/(\d{4})/, "$3-$1-$2"); // MM/DD/YYYY to YYYY-MM-DD

      const groupedSlots = {};

      // Loop through all locations and specialists
      for (const specialist of this.activity.locations[this.locationIndex]
        .specialists) {
        const { name, dates } = specialist;

        // Loop through dates of each specialist
        for (const { date: specialistDate, slots } of dates) {
          const formattedSpecialistDate = new Date(specialistDate)
            .toLocaleDateString("en-CA", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            })
            .replace(/(\d{2})\/(\d{2})\/(\d{4})/, "$3-$1-$2");

          // Match the input date
          if (formattedSpecialistDate === formattedInputDate) {
            if (!groupedSlots[name]) {
              groupedSlots[name] = [];
            }

            // Add slots to the corresponding specialist
            groupedSlots[name].push(...slots);
          }
        }
      }

      // Filter unique slots per specialist
      for (const specialistName in groupedSlots) {
        groupedSlots[specialistName] = groupedSlots[specialistName].reduce(
          (acc, slot) => {
            if (!acc.some((s) => s.start === slot.start)) {
              acc.push(slot);
            }
            return acc;
          },
          []
        );
      }

      return groupedSlots;
    },

    deleteitem() {},
  },
};
</script>
